.pdp.pdp-details {
  width: 100%;
  float: left;
  margin: 20px 0 0 0;
  padding: 0;
  text-align: justify;

  li {
    list-style: initial !important;
  }

  .pdp-details__accordion {
    width: 100%;

    h5 {
      margin: 4px 0;
      font-size: 15px;
    }

    .customPdpTabs {
      padding: 0;
    }

    // Div for Panel Header
    div.flex.py-3 {
      height: 53px;
      align-items: center;

      label.align-middle {
        padding-top: .25rem;
      }
    }

    // Div for arrow icon
    div.float-right {
      margin-top: auto;
      margin-bottom: auto;

      // Arrow icon
      svg.fill-current {
        width: 12px;
        height: 7px;
      }
    }
  }
}
