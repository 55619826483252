@import 'components/Sitewide/screensizes';

.custom-navigation-ul {
  li.navigation-main-li {
    @media (min-width: 801px) {
      margin: 0 2px !important;
    }
  }
}


.cartPreviewOverlay {
	position: absolute;
	right: 0;
	width: 100vw;
	height: 100vh;
	top: -10px;
	z-index: 150;
}

@media (max-width: $screen-medium) {
	.cartPreviewOverlay {
		top: 0px;
		background-color: #00000070;
	}
}

.cartPreview {
	max-width: 500px;
	max-height: calc(100vh - 80px);
	overflow: auto;
	position: absolute;
	right: 0;
	top: 100%;
	margin: 16px auto;
	width: calc(100vw - 20px);
	border: 1px solid #dddddd;
}

@media (max-width: $screen-medium) {
	.cartPreview {
		top: 0;
		margin: 40px auto;
		width: 90vw;
		max-height: 75vh;
	}
}
.modal {
	visibility: hidden
}
.modal-close {
	text-decoration: none
}
@media (min-width:551px) {
	.modal {
		transform: translateY(-50%)
	}
}

.modal-background {
	background: #000;
	background: rgba(51, 51, 51, .95);
	bottom: 0;
	display: none;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1004;
	left: 0;
}

.has-activeModal .modal-background {
	display: block
}

.modal {
	border-radius: 0;
	display: none;
	z-index: 1005;
	background-color: #fff;
	border: 0 solid #e5e5e5;
	box-shadow: none;
	position: fixed;
	margin: 0;
	max-height: 90%;
	max-width: 920px;
	min-height: 240px;
	outline: 0;
	overflow: hidden;
	width: 90%;
	padding: 0;
	left: 50%;
	top: 50%!important;
	transform: translate(-50%, -50%)
}

@media only screen and (max-width:551px) {
	.modal {
		min-height: 100vh
	}
}

@media (min-width:1261px) {
	.modal--large {
		width: 1280px
	}
}

.modal-header {
	padding-left: 2.25rem;
	padding-right: 3.03571rem
}

.modal-header-title {
	font-size: 22px;
	text-align: center
}

@media (min-width: calc($screen-medium + 1px)) {
	.modal-header-title {
		font-size: 25px
	}
}

.modal-close {
	height: 2.28571rem;
	width: 2.28571rem;
	color: #757575;
	font-size: 2.5rem;
	line-height: 2.28571rem;
	padding: 0;
	position: absolute;
	text-align: center;
	z-index: 50
}

.modal-close img {
	height: 15px;
	width: 15px
}

.modal-close:hover {
	color: #999
}

.modal-body {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	padding: 20px 20px 0
}

.modal--alert {
	background-color: #fff!important;
	border-radius: 4px;
	font-family: Karla, Arial, Helvetica, sans-serif;
	padding: 40px 20px 20px;
	text-align: center
}

.modal--alert .modal-content {
	color: #333;
	font-size: 1.14286rem
}

.modal--alert .button-container {
	margin: 25px 0 0
}

.modal--alert .button-container .confirm {
	background-color: #000;
	border-color: #000;
	color: #fff
}

.modal--alert .button-container .confirm:focus,
.modal--alert .button-container .confirm:hover {
	background-color: #666;
	border-color: #666;
	color: #fff
}

.modal--alert .button-container .confirm:active {
	background-color: #000;
	border-color: #000;
	color: #fff
}
.modal .column,
.modal .columns {
	min-width: 0
}

.modal>:first-child {
	margin-top: 0
}

.modal>:last-child {
	margin-bottom: 0
}
.modal.quickViewModal {
	max-width: 768px
}

.quickSearchResults .modal-close {
	display: none
}

.modal--alert .modal-content {
	color: #333;
	font-size: 1.14286rem
}

.modal .productView {
	padding-bottom: 0;
	padding-top: 0
}
@media (min-width:769px) {
	.quickSearchResults {
		margin-top: 0
	}
	.quickSearchResults .modal-close {
		display: block
	}
}

@media only screen and (min-width:551px) {
	.modal {
		margin: 0 auto;
		max-width: 80%;
		right: 0;
		width: 900px;
		top: 50%!important
	}
}

.modal--large {
	max-width: calc(100% - 16px) !important;
	max-height: 90%;
	width: 1280px
}

.modal--small {
	width: 500px
}

.modal-header {
	border-bottom: 1px solid #e5e5e5;
	padding: 1rem 0;
	position: relative
}

.modal-header-title {
	margin: 0
}

.modal-body>:last-child {
	margin-bottom: 0
}


.modal-close {
	cursor: pointer;
	font-weight: 400;
	top: 1.05rem;
	right: .75rem;
	transform: translateY(none)
}

.modal-close .icon {
	height: 1.42857rem;
	width: 1.42857rem
}

@media print {
	.modal {
		background: #FFF!important;
		display: none
	}
}



.navUser-action.mobile {
	display: block;
	position: relative
}

.navUser-action.desktop {
	display: none
}

@media (min-width:769px) {
	.navUser-action.mobile {
		display: none
	}
	.navUser-action.desktop {
		display: inline-block
	}
}


.header.is-open #quickSearch,
.header.is-open #search-app-container,
.header.is-open .navUser {
	background: #000;
	opacity: .5;
	z-index: 0
}

@media screen and (max-width:769px) {
	.has-cart .header {
		z-index: 101;
		border-color: grey;
		background: grey;
	}
	.has-cart .navUser {
		background: transparent;
		z-index: 110;
	}
	.has-cart .dropdown-account-mobile{
		opacity: .5
	}
}

.navUser-container {
	max-width: 940px;
	margin: auto
}


@media (min-width:551px) {
	.navUser-item {
		float: left
	}
	.dropdown--quickSearch {
		padding: 3rem
	}
}

.navUser-action--quickSearch.is-open:before {
	border: 10px inset;
	content: "";
	display: block;
	height: 0;
	width: 0;
	border-color: transparent transparent #e5e5e5;
	border-bottom-style: solid;
	bottom: 0;
	left: 50%;
	position: absolute;
	transform: translateX(-50%)
}



.navUser {
	top: 28px;
	z-index: 5;
	background: transparent
}

.navUser ol,
.navUser ul {
	list-style: none;
	margin: 0;
	padding: 0
}

.navUser ol ol,
.navUser ol ul,
.navUser ul ol,
.navUser ul ul,
.navUser {
	list-style: none;
	margin-bottom: 0
}

@media (max-width: 1023px) {
	.nav-cart-icon {
		filter: brightness(1) invert(1) hue-rotate(100deg);
	}
}

.navUser-action,
.navUser-item {
	display: block
}

@media (min-width: $screen-med-large) {
	.navUser {
		padding: 0;
		position: relative;
		right: auto;
		top: auto;
		transform: none;
		z-index: 150;
		background: #EDEDED
	}
}

.navUser .dropdown-menu {
	background-color: #fff;
	border: 1px solid #ccc;
	box-shadow: 0 1px 2px rgba(0, 0, 0, .13);
	position: absolute
}

.navUser-action--quickSearch.is-open {
	position: relative
}

.navUser-action {
	color: #000;
	padding: 6px;
	text-decoration: none;
	font-size: 12px
}

.navUser-action.is-open,
.navUser-action:hover {
	color: #757575
}

.navUser-action.is-open svg,
.navUser-action:hover svg {
	fill: #757575;
	stroke: #757575
}

.navUser-action .icon {
	height: .57143rem;
	width: .57143rem;
	margin: -1px 0 0 .21429rem
}

.navUser-action svg {
	fill: #000;
	stroke: #000
}

.navUser-action--currencySelector+.dropdown-menu:before {
	left: auto!important;
	right: .78571rem
}

.navUser-action--currencySelector+.dropdown-menu:after {
	left: auto!important;
	right: .92857rem
}

.navUser-action-divider {
	border-right: 1px solid #e5e5e5;
	color: #757575;
	display: inline-block;
	padding: 0 1.42857rem 0 0;
	text-decoration: none
}

.navUser-item {
	display: none
}

.navUser-item--cart {
	display: block
}

@media (min-width:1024px) {
	.navUser-item {
		display: block
	}
	.navUser-item.navUser-item--social {
		margin-top: .35714rem;
		padding-right: .35714rem
	}
	.navUser-item.navUser-item--divider {
		font-size: 1.78571rem;
		margin-top: .57143rem;
		padding-left: .14286rem
	}
	.navUser-item--cart .navUser-action {
		color: #fff;
		background: rgb(59, 95, 88)
	}
}

.navUser-item--cart .navUser-action.is-open,
.navUser-item--cart .navUser-action:hover {
	color: #fff
}

.navUser-item--cart .navUser-action img {
	height: 22px;
	margin: 3px 0 0
}

.navUser-item--cart .navUser-action img.cart-icon-desktop {
	display: none
}

@media (min-width: 1024px) {
	.navUser-item--cart .navUser-action img {
		width: 19px;
		margin: -3px 3px 0 0
	}
	.navUser-item--cart .navUser-action img.cart-icon-desktop {
		display: inline-block
	}
	.navUser-item--cart .navUser-action img.cart-icon-mobile {
		display: none
	}
	.navUser-item--cart .navUser-action .cart-quantity::after {
		content: ')'
	}
	.navUser-item--cart .navUser-action .cart-quantity::before {
		content: '('
	}
}

@media screen and (max-width:1023px) {
	.navUser-item--cart .navUser-action .cart-quantity {
		background: #747474;
		border: 1px solid #747474;
		border-radius: 50%;
		min-width: 19px;
		display: inline-block;
		position: absolute;
		right: 4px;
		top: 0;
		color: #fff;
		text-align: center;
		height: 19px;
		line-height: 18px
	}
}

.navUser-item--cart .dropdown-menu {
	max-width: 22.85714rem;
	border: none
}

.navUser-item--cart .dropdown-menu.is-open {
	top: auto!important;
	left: 0!important;
	right: 0;
}

@media (min-width:1024px) {
	.navUser-item--cart .dropdown-menu.is-open {
		right: 0;
		border: 1px solid #ccc
	}
}

.navUser-item--cart .dropdown-menu.is-open:after,
.navUser-item--cart .dropdown-menu.is-open:before {
	left: auto
}

.navUser-item--cart .dropdown-menu.is-open:before {
	right: .42857rem
}

@media (min-width:1024px) {
	.navUser-item--cart .dropdown-menu.is-open:before {
		right: .78571rem
	}
}

.navUser-item--cart .dropdown-menu.is-open:after {
	right: .57143rem
}

.navUser-item-cartLabel {
	display: none
}

@media (min-width:1024px) {
	.navUser-item--cart .dropdown-menu.is-open:after {
		right: .92857rem
	}
	.navUser-item-cartLabel {
		display: inline
	}
}

.navUser-item--compare {
	display: none
}

.navUser-item--account,
.navUser-item--compare.show,
.navUser-item--zgold {
	display: block
}

.navUser-action.zgoldButton {
	padding: 8px 6px 8px 6px;
}

.navUser-item--account span, .navUser-item--zgold span {
	display: none
}


.navUser-item--account .navUser-action,
.navUser-item--zgold .navUser-action {
	display: inline-block
}

.navUser-item--account .navUser-action img,
.navUser-item--zgold .navUser-action img {
	height: 22px;
	margin-top: 2px
}

@media (min-width:1024px) {
	.navUser-item--account span,
	.navUser-item--zgold span {
		display: inline-block
	}
	.navUser-item--account .navUser-action img,
	.navUser-item--zgold .navUser-action img {
		margin: -3px 6px 0 0;
		width: 15px
	}
}

.navUser-item--zgold span {
	display: block !important;
}

@media (min-width: 1024px) {
	.navUser-item--zgold .navUser-action {
		max-height: 30px;
	}
}

.navUser-item--zgold .navUser-action {
	display: flex !important;
	flex-direction: row;
	align-items: center;
}

.navUser-item--zgold .navUser-action img {
	margin-right: 0.5rem;
	margin-top: 1px !important;
}

@media (max-width: 768px ) {
	.navUser-item--zgold .navUser-action span {
		font-size: 14px !important;
	}
}
@media (min-width: 768px ) {
.navUser-item--zgold .navUser-action span {
	font-size: 12px !important;
	}
}

@media (max-width: 768px) {
	.navUser-item.desktop {
		display: none;
	}
}

.navUser:after,
.navUser:before {
	content: " ";
	display: table
}

.color-module>div {
	transform: translateY(-50%)
}

.icon,
.icon svg,
.navUser-action svg,
.socialLinks .icon svg {
	transition: all .15s ease
}

.account--addReturn-row:after,
.cart-item,
.cart-item-info,
.facetedSearch-toggle:after,
.navUser:after,
.productCarousel:after,
.socialLinks:after {
	clear: both
}

.countPill {
	background-color: #333;
	border-radius: 50%;
	color: #fff;
	display: none;
	font-size: 12px;
	font-weight: 700;
	height: 20px;
	line-height: 20px;
	margin-left: .21429rem;
	text-align: center;
	width: 20px
}

.countPill--positive {
	display: inline-block
}

.countPill--alt {
	background-color: #ccc;
	color: #333
}

.cart-main-div #menu,
.cart-main-div #quickSearch,
.cart-main-div .cart-additionalCheckoutButtons,
.cart-main-div .mobileMenu-toggle,
.cart-main-div [data-section-type=newsletterSubscription] {
	display: none
}

.navUser-section:after{
	clear: both
}

.navUser-section:after,
.navUser-section:before,
.navUser:after,
.navUser:before {
	content: " ";
	display: table
}

.navUser-section {
	float: left
}

.navUser-section--alt {
	float: right;
	margin-left: 1.5rem
}

.navUser-section+.navUser-section {
	margin-left: 1.5rem
}


.navUser-section {
	display: -ms-flexbox;
	display: flex
}
@media (min-width:1024px) {
	.navUser-section {
		position: relative;
		min-width: 9999px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		padding: 0;
	}
}

.cart-main-div .navUser-section--alt {
	display: none
}

@media (min-width:769px) {
	.empty-cart {
		padding: 0
	}
	.cart-main-div .navUser-section--alt,
	.cart-main-div [data-section-type=newsletterSubscription] {
		display: block
	}
	.cart-main-div #quickSearch {
		display: block
	}
}

#TypeAheadResult {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline
}


#TypeAheadResult .typehead .category_list {
	vertical-align: middle;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-align: center;
	align-items: center
}

#TypeAheadResult .typehead ul {
	list-style: none;
	margin: 0;
	padding: 0
}

#TypeAheadResult .typehead li {
	background: #f7f7f7;
	padding: 5px 10px;
	font-weight: 700;
	list-style: none;
	margin: 0
}

#TypeAheadResult .typehead li li {
	font-weight: 400;
	border-top: 1px solid #e7e7e9;
	background: #fff
}

#TypeAheadResult .typehead li .product_list {
	display: block;
	overflow: hidden
}

#TypeAheadResult .typehead li img {
	width: 50px;
	height: 50px;
	border: 1px solid #e6e6e7;
	float: left;
	margin-right: 10px
}

#TypeAheadResult .typehead li .see_all {
	float: right;
	text-decoration: underline;
	font-size: 12px
}

#TypeAheadResult .typehead li .see_all a {
	color: #555;
	text-decoration: none
}

.SearchBarInput > input {
	border: none;
	border-bottom: 1px var(--color-input) solid;
}

.search-input-parent {
	text-align: left;
}

.search-input-parent .highlighted-search-hit {
	font-weight: 1000;
}

@media (min-width:1023px) {
	.search-input-parent {
		flex-grow: 1;
		max-width: 32vw;
	}
}

@media (min-width:769px) {
	#TypeAheadResult #TypeAheadResult {
		display: block;
		margin-top: -1px;
		overflow: hidden;
		border-bottom-right-radius: 6px;
		border-bottom-left-radius: 6px;
		-moz-border-bottom-right-radius: 6px;
		-moz-border-bottom-left-radius: 6px;
		background-color: #FFF;
		line-height: 17px;
		font-size: 12px
	}
	
	#TypeAheadResult .typehead .arrow {
		width: 15px;
		height: 8px;
		position: relative;
		margin: -8px 0 0 22px
	}
	#TypeAheadResult .typehead ul {
		margin: 0;
		padding: 0
	}
	#TypeAheadResult .typehead li {
		background: #e7e7e9;
		line-height: 24px;
		font-weight: 700;
		list-style: none;
		padding: 0 13px;
		width: 100%;
		margin: 0
	}
	#TypeAheadResult .typehead li ul {
		margin: 0 -13px;
		padding: 0
	}
	#TypeAheadResult .typehead li li {
		font-weight: 400;
		border-top: 1px solid #e7e7e9;
		background: #FFF;
		float: left
	}
	#TypeAheadResult .typehead .see_all {
		float: right;
		text-decoration: underline;
		font-size: 12px;
		font-weight: 400
	}
	#TypeAheadResult .typehead li li img {
		width: 50px;
		height: 50px;
		border: 1px solid #e6e6e7;
		margin: 0 14px 0 0;
		float: left;
		vertical-align: middle
	}
	#TypeAheadResult .typehead .category_list,
	#TypeAheadResult .typehead .product_list {
		float: left;
		width: 100%;
		line-height: normal;
		padding: 10px 0;
		text-align: left;
	}
	#TypeAheadResult .typehead .product_list {
		vertical-align: middle
	}
	#TypeAheadResult .typehead .divhover,
	#TypeAheadResult .typehead .product_list:hover {
		cursor: pointer
	}
	#TypeAheadResult .typehead li li .img {
		float: none
	}
	#TypeAheadResult .autocomplete-result:focus {
		background: #CCC;
		outline: 0
	}
}


.cart-totals {
	list-style: none;
	margin-left: 0;
	margin-bottom: 10px;
	width: 100%
}

.cart-totals ol,
.cart-totals ul {
	list-style: none;
	margin-bottom: 0
}

@media (min-width:769px) {
	.cart-totals {
		margin-bottom: 1rem
	}
}

.cart-total-label {
	color: #757575
}

@media (min-width:551px) {
	.cart-total-label {
		width: 50%
	}
}

@media (min-width:769px) {
	.cart-total-label {
		display: block;
		padding-left: 0;
		text-align: left
	}
	.cart-total-value {
		width: 50%
	}
}

.cart-total-label a {
	font-size: 12px
}

.cart-total-value {
	text-align: right
}

.cart-total-label,
.cart-total-value {
	float: left;
	padding: .5rem 0;
	width: 50%
}

@media (min-width:769px) {
	.cart-total-label,
	.cart-total-value {
		padding: 1rem 0
	}
	.cart-actions {
		min-width: 320px
	}
}


/*____________________________*/

// For ExtendLineItem
.cart-actions,
.cart-actions:after,
.cart-gift-certificate-form:after,
.cart-total:after,
.coupon-form:after,
.estimator-form-row:after {
	clear: both
}

#PDP_Details_description a {
	font-weight: bold;
	text-decoration: underline;
}
