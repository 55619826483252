:root {
  --max-extend-width: 425px;
}

#extend-offer {
  flex-grow: 1;

  iframe {
    margin: 16px 0 10px;
  }
}
