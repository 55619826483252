.out-of-stock-alert {
  text-align: left;
  width: 100%;
  color: #d8000c;
  background-color: #ffd2d2;
  padding: 10px;
  border-left: 2px solid #d8000c;
}

form#add-to-cart-form {
  input[type=checkbox]:not(:checked), input[type=radio]:not(:checked) {
    display: inline-block;
    position: absolute;
  }
}
